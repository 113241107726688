// ==========================================================================
// Buttons & Links
// ==========================================================================
button {
    appearance: none;
    background-color: inherit;
    border: none;
    cursor: pointer;
    padding: 0;
}

.cursor-pointer {
    cursor: pointer;
}

a {
    color: $primary;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.btn-clear {
    color: rgb(0, 122, 255);
    font-size: 0.9em;
    font-family: $font-family-buttons;
    font-weight: $font-weight-medium;
    box-sizing: border-box;
}

.btn {
    background-color: transparent;
    border-color: transparent;
    border-radius: $border-radius-md;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: 0.9em;
    font-family: $font-family-buttons;
    font-weight: $font-weight-medium;
    letter-spacing: 1px;
    line-height: 0.875em;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.25s all linear;
    width: 100%;
    &.btn-primary,
    &.btn-primary-inverted,
    &.btn-secondary,
    &.btn-grey,
    &.btn-white,
    &.btn-accept,
    &.btn-deny,
    &.btn-max,
    &.btn-tag,
    &.btn-text {
        outline: none;
        padding: 16px;
        &.btn-shrink {
            width: auto;
        }
        &:hover {
            text-decoration: none;
        }
    }
    &.btn-primary {
        background-color: $primary;
        border-color: $primary;
        box-shadow: $global-box-shadow-y;
        color: $white;
        &:hover {
            background-color: $primary-lighter;
            border-color: $primary-lighter;
        }
    }

  &.btn-grey {
    background-color: $grey-lighter;
    border-color: $grey-lighter;
    color: $dark-blue;

    &:hover {
      background-color: $grey-light;
      border-color: $grey-light;
    }
  }

    &.btn-accept {
        background-color: $accept;
        border-color: $accept;
        box-shadow: $global-box-shadow-y;
        color: $white;
        &:hover {
            background-color: lighten($accept, 4%);
            border-color: lighten($accept, 4%);
        }
    }
    &.btn-deny {
        background-color: $deny;
        border-color: $deny;
        box-shadow: $global-box-shadow-y;
        color: $white;
        &:hover {
            background-color: lighten($deny, 4%);
            border-color: lighten($deny, 4%);
        }
    }
    &.btn-secondary {
        background-color: $secondary;
        border-color: $secondary;
        box-shadow: $global-box-shadow-y;
        color: $white;
        &:hover {
            background-color: $secondary-lighter;
            border-color: $secondary-lighter;
        }
    }
    &.btn-white {
        background-color: white;
        box-shadow: $global-box-shadow-y;
        border-color: $grey-lightest;
        color: $secondary;

        &:hover {
            background-color: $grey-lightest;
            border-color: $grey-lightest;
        }
    }
    &.btn-text {
        color: $secondary;
        letter-spacing: normal;
        line-height: inherit;
        text-decoration: underline;
        text-transform: none;
        &:hover {
            text-decoration: none;
        }
    }
    &.btn-disabled {
        background-color: $grey-lighter;
        border-color: $grey-medium;
        box-shadow: none;
        color: $grey-dark;
        cursor: not-allowed;
        font-weight: $font-weight-medium;
        &:hover {
            background-color: $grey-lighter;
            border-color: $grey-lighter;
        }
      }
    &[disabled] {
        @extend .btn-disabled
    }
}

@include media-breakpoint-up(sm) {
    .btn {
        max-width: 300px;
    }
}

@include media-breakpoint-up(lg) {
    .btn {
        max-width: none;
    }
}

.smallBtn {
    div {
        font-size: 0.9em;
    }
    svg {
        transform: scale(0.9);
    }
}
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

// What the fuck is this exactly!!!!!??
div, button {
	display: flex;
	flex-direction: column;
}

.row {
	flex-direction: row;
}

.justify-content-center {
	justify-content: center;
}

* {
	font-family: "Lausanne";
}

button, input, optgroup, select, textarea {
	line-height: 1.15em;
}

p {
	line-height: 1.5em;
}

.full-screen-container {
	width: 100vw;
	height: 100vh;
	overflow-y: scroll;
}

.full-width {
	width: 100%;
}

.do-not-break-out {

	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;

  }

*::placeholder {
	/* Chrome/Opera/Safari */
	color: #747474!important;
	font-weight: 400;
	font-size: 18;
	opacity: 1;
	transition: all 300ms;
}
*::-moz-placeholder {
	/* Firefox 19+ */
	color: #747474!important;
	font-weight: 900;
	font-size: 18;
	opacity: 1;
	transition: all 300ms;
}
*:-ms-input-placeholder {
	/* IE 10+ */
	color: #747474!important;
	font-weight: 900;
	font-size: 18;
	opacity: 1;
	transition: all 300ms;
}
*:-moz-placeholder {
	/* Firefox 18- */
	color: #747474!important;
	font-weight: 900;
	font-size: 18;
	opacity: 1;
	transition: all 300ms;
}

.hide-scrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.box-none {
	pointer-events: none;
}
.box-none * {
	pointer-events: auto;
}

.nav-bar-logo {
	max-width: 12rem;
	width: 100%;
}

.hamburgerMenuContainer {
	background-color: #fff;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1000;
}

// gap size schema
.column-gap-default {
	column-gap: $space_default;
}
.column-gap-size1 {
	column-gap: $space_size1;
}
.column-gap-size2 {
	column-gap: $space_size2;
}
.column-gap-size3 {
	column-gap: $space_size3;
}
.column-gap-size4 {
	column-gap: $space_size4;
}
.column-gap-size5 {
	column-gap: $space_size5;
}
.column-gap-size6 {
	column-gap: $space_size6;
}
.column-gap-size8 {
	column-gap: $space_size8;
}
.column-gap-size9 {
	column-gap: $space_size9;
}
.column-gap-size10 {
	column-gap: $space_size10;
}
.column-gap-size11 {
	column-gap: $space_size11;
}
.column-gap-size12 {
	column-gap: $space_size12;
}

// ==========================================================================
// Forms > Select field, e.g. used in product-template.
// Based on http://wtfforms.com
// ==========================================================================

// Forms
// -------------------------
input {
  appearance: none;
  background-color: $white;
  border-radius: $border-radius-md;
  border: $border-thickness solid $grey-dark;
  color: $black;
  display: inline-block;
  line-height: 2;
  margin: 0;

  &[disabled] {
    @extend .text-muted;

    background-color: $grey-lighter;
    border-color: $grey-light;
    cursor: default;
  }

  &[readonly] {
    @extend .text-muted;

    border-color: $grey-lighter;
    cursor: default;
  }
}

textarea {
  background-color: $white;
  border-radius: $border-radius-md;
  border: $border-thickness solid $grey-dark;
  color: $black;
  line-height: 1.5;
}

input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"],
input[type="date"],
input[type="month"],
textarea {
  box-sizing: border-box;
  cursor: pointer;
  padding: 0.5em 1em;
  width: 100%;

  &.phone-input {
    padding-left: 3em;
  }
}

input[type="date"] {
  min-height: 2.5rem;
}

.MuiInputBase-input { height: unset !important; }

// use .form-group as wrapper for input fields.
.form-group {
  display: block; // animate label
  margin-bottom: 1rem;
  margin-top: 1rem;
  position: relative; // animate label

  // animate label
  .form-control {
    &:not(.is-active) + label {
      font-size: 100%;
      opacity: 0.75;
      top: 1em;
      &.phone-hint {
        left: 3em;
      }
    }

    &::-webkit-input-placeholder,
    &::placeholder {
      opacity: 0;
      transition: all 0.2s;
    }

    &:focus {
      color: $secondary;
      outline-color: $primary;

      & + label {
        color: $primary;
      }
    }

    &.with-error {
      border-color: $danger;
    }
  }

  .pre-number {
    position: absolute;
    top: .88em;
    font-weight: $font-weight-medium;
    color: $grey-dark;
    border-right: 1px solid $grey-dark;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .suggestions {
    background-color: #FFF;
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    position: absolute;
    z-index: 999;
    top: 38px;
    max-height: 160px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(100% - 2px);
    cursor: pointer;
    font-family: inherit;
    font-size: 100%;

    li {
      padding: 12px 10px;
      margin: auto;

      &:hover {
        background-color: #DDD;
        font-weight: 500;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid #999;
      }
    }
  }

  .error-text {
    color: $danger;
  }

  // animate label
  label {
    color: $grey-medium;
    cursor: text;
    font-size: 75%;
    left: 0.75rem;
    line-height: 1;
    opacity: 1;
    padding: 0 1px;
    position: absolute;
    top: -0.5em;
    transition: all 0.2s;
    z-index: 3;

    &::after {
      content: " ";
      display: block;
      position: absolute;
      background: $white;
      height: 10px;
      top: 20%;
      left: -0.2em;
      right: -0.2em;
      z-index: -1;
    }
  }

  small {
    color: $grey-dark;
    font-size: 65%;
    display: block;
    line-height: 1.5em;
    margin: 0.5em 0 0 0.5em;
  }

  // styles for attaching a box to the input field.
  &.append-btn {
    display: flex;

    input,
    .btn {
      line-height: 2;
    }

    input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right-width: 0;
    }

    .btn {
      background-color: $grey-lighter;
      border: $border-thickness solid $grey-dark;
      border-left-width: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      cursor: none;
      flex-shrink: 0;
      width: auto;
    }
  }

  .form-selector {
    width: 100%;
    height: 60px;
    padding: 15px;
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    background-color: #FFF;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    border: 1px solid #828282;
    border-radius: 4px;

    &.with-error {
      border-color: $danger;
    }
  }
}

@include media-breakpoint-up(sm) {
  .suggestions,
  input,
  .form-selector {
    max-width: 300px;
  }

  .modal {
    input,
    .form-selector {
      max-width: unset;
    }
  }
}

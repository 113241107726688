.feedback-overlay {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    padding: $space_size2 $space_size3;
    z-index: $zindex-modal;
    background-color:$white;
    border: $border-width $border-style $black;
    font-size: 35px;
    overflow: scroll;

    @include border-radius(20px);
    @include box-shadow($box-shadow);
    @include media-breakpoint-up(md) {    
        top: auto;
        left: auto;
        max-width: 656px;
        right: $space_size2;
        bottom: $space_size2;  
        padding: $space_size5 $space_size8;
    }

    .content-container {

        .description {
            padding: 0px $space_size5;
            font-size: $font-size-sm;

            .bold {
                font-weight: $font-weight-bold;
            }
        }

        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: $space_size2 $space_default;

            .item {
                font-weight: $font-weight-bold;
                font-size: $space_size1;
                
                &.dimmed {
                    color: #747474;
                    font-weight: 400;
                }
            }
        }

        .rating-items-container {
            display: flex;
            flex-direction: row;
            column-gap: 0;
            margin-bottom: $space_size4;
            @include media-breakpoint-up(md) {    
                column-gap: $space_default;
            }


            .item {
                flex: 1;
                display: flex;
                font-size: $space_size1;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background-color: #F1F1F1;
                @include border-radius(1000px);
                overflow: hidden;
                aspect-ratio: 1;

                &.transparent-back {
                    background-color: transparent;
                }

                &:not(.non-touchable):hover {
                    background-color: #d7d7d7;
                }
                &.non-touchable {
                    cursor: auto;
                }
                &.selected {
                    border: 1px solid #000000;
                }
            }
        }
    }

    .top-left-text {
        position: absolute;
        left: $space_size2;
        top: $space_size2;
        font-size: 14px;
        color: #6B6B6B;
    }

    .close-button {
        position: absolute;
        background-color: transparent;
        padding: $space_size2;
        cursor: pointer;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
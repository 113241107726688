// ==========================================================================
// Variables
// ==========================================================================

$border-radius-md: 4px;
$border-radius-lg: 8px;
$border-radius-xxl: 100px;

$border-thickness: 1px;

$global-text-shadow: 0 4px 4px 0 rgba($black, 0.25);
$global-box-shadow-y: 0 4px 3px 0 rgba($black, 0.25);
$global-box-shadow-x: 0 0 3px 2px rgba($black, 0.25);

$enable-shadows: true;
$box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.0403893), 0px 16.3055px 41.3222px rgba(0, 0, 0, 0.0596107), 0px 4.85457px 12.3027px rgba(0, 0, 0, 0.1);

$space_default: 8px;
$space_size1: 16px;
$space_size2: 24px;
$space_size3: 32px;
$space_size4: 40px;
$space_size5: 48px;
$space_size6: 56px;
$space_size8: 64px;
$space_size9: 72px;
$space_size10: 80px;
$space_size11: 88px;
$space_size12: 96px;

$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-family-buttons: 'Lausanne', sans-serif;

$size-header: 56px; // important, when header is flying above hero.
$size-mobile-nav: 280px;
$collapsed-nav-width: 70px;

// ==========================================================================
// Colors
// ==========================================================================

// Non-Colors
// -------------------------
$white: #fff;
$grey-lightest: #f2f2f2;
$grey-lighter-0: #f6f6f6;
$grey-lighter: #e2e2e2; // DNAmed Brand Color ~ Grey 5
$grey-light: #c9c9c9; // Black - Focus Overlay
$grey-medium: #878787;
$grey-dark: #828282;
$grey-darker: #5b738e; // Black - Medium Empbasis
$black: #20242c; // High Emphasis
$realblack: #000;
$dark-blue: #004562;
$pale-blue: #5b738e;
$light-blue: #7aaed3;
$studie-blue: #3ba5ac;
$monochrome-grey4: #979797;

$monochrome-grey4: #979797;
$monochrome-grey5: #3e3e3e;

// Template Colors
// -------------------------
$primary: #0092cf;
$primary-lighter: lighten($primary, 8%);
$primary-darker: darken($primary, 8%);
$secondary: #004562;
$secondary-lighter: lighten($secondary, 8%);
$tertiary: #79bccd;
$accept: #27ae60;
$deny: #eb5757;

// Notification Colors
// -------------------------
$success: #50b899;
$danger: #eb5757;
$attention: #f2c94c;
$info: #56ccf2;
$incomplete: #7579d1;
// Social Networks
// -------------------------
// $xing:                #018084;
// $linkedin:            #2867B2;

// Background Colors
// -------------------------
.btn-light-background {
  background-color: $grey-lighter-0;
  &:hover {
    background-color: $grey-light;
  }
}
.bg-wht {
  background-color: $white !important;
}

.bg-gry {
  background-color: $grey-lighter;
}

.bg-gry-0 {
  background-color: $grey-lighter-0;
}

// Font Colors
// -------------------------
.font-wht {
  color: $white;
}

.color-info {
  color: $info;
}

.color-success {
  color: $success;
}

.color-primary {
  color: $primary;
}

.color-gry-medium {
  color: $grey-medium;
}

.color-mono-gry-4 {
  color: $monochrome-grey4;
}

.color-mono-gry-5 {
  color: $monochrome-grey5;
}

.font-blk {
  color: $black;
}

.font-danger {
  color: $danger;
}

.font-dark-blue {
  color: $dark-blue;
}

.color-pale-blue {
  color: $pale-blue;
}

svg {
  &.icon-black {
    path {
      fill: black;
    }
  }
}
